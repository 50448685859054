import NavBar from "../../components/NavBar/NavBar"
import Navigation from "../../components/Navigation/Navigation"
import styles from "./Works.module.css"
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import { FaChevronUp } from "react-icons/fa6";

type Experience = {
    poste: string,
    company: string,
    place: string,
    from: number | string,
    to: string | number,
    type: string,
    logo: string
}

const Works = () => {

    const experiences:Experience[] = [
        {
            poste: "Software Engineer",
            company: "Safran",
            place: "Paris, France",
            from: "November 2024",
            to: "March 2025",
            type: "Internship",
            logo: "safran.png"
        },
        {
            poste: "Software Engineer",
            company: "Thales",
            place: "Dubaï, UAE",
            from: "June 2024",
            to: "July 2024",
            type: "Internship",
            logo: "thales.png"
        },
        {
            poste: "Software Engineer",
            company: "Thales",
            place: "Dubaï, UAE",
            from: "June 2023",
            to: "July 2023",
            type: "Internship",
            logo: "thales.png"
        },
        {
            poste: "Treasurer",
            company: "SEPEFREI Junior-Enterprise",
            place: "Paris, France",
            from: "January 2024",
            to: "present",
            type: "Full time",
            logo: "sepefrei.svg"
        },
        {
            poste: "Frontend Engineer",
            company: "Diivii",
            place: "Paris, France",
            from: "January 2023",
            to: "February 2023",
            type: "Internship",
            logo: "diivii.png"
        },
        {
            poste: "CISO/DSI",
            company: "SEPEFREI Junior-Enterprise",
            place: "Paris, France",
            from: "January 2022",
            to: "January 2024",
            type: "Full time",
            logo: "sepefrei.svg"
        },
        {
            poste: "Fullstack Engineer",
            company: "SEPEFFREI Junior-Enterprise",
            place: "Paris, France",
            from: 2021,
            to: "present",
            type: "Freelance",
            logo: "sepefrei.svg"
        },
    ]

    return(
        <div className={styles.content}>
            <img src="/images/grid.jpg" className={styles.bg} alt="Grid box" />
            <NavBar/>
            <div className={styles.top}>
                <div></div>
                <h1>WORK EXPERIENCES</h1>
            </div>


            <div className={styles.subContent}>

                <FaChevronUp style={{width: "25px", height: "25px", zIndex: 90, position: "absolute", left: "50%", transform: "translateX(-50%)", color: "black", top: "16px"}} />

                <Timeline position="alternate">
                    {experiences.map((exp, index) => (
                        <TimelineItem key={index}>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="justify"
                                variant="body1"
                                color="text.secondary"
                                >
                                <img src={`/companies/${exp.logo}`} alt={`Logo ${exp.company}`} className={styles.logo} />
                            </TimelineOppositeContent>
                            <TimelineSeparator >
                                <TimelineConnector sx={{ backgroundColor: 'black', width: "2px" }} />
                                <div style={{width: "10px", height: "10px", backgroundColor: "black", borderRadius: "50%", margin: "10px"}}></div>
                                <TimelineConnector sx={{ backgroundColor: 'black', width: "2px" }} />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '50px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    {exp.poste} ({exp.type})
                                </Typography>
                                <Typography>{exp.company} - {exp.place}</Typography>
                                <Typography sx={{color: "silver"}}>from {exp.from} to {exp.to}</Typography>
                            </TimelineContent>
                            
                        </TimelineItem>
                    ))}


                        <TimelineSeparator>
                            <TimelineConnector />

                                <div style={{width: "5px", height: "5px", backgroundColor: "black", borderRadius: "50%", margin: "5px", marginTop: "10px"}}></div>
                                <div style={{width: "4px", height: "4px", backgroundColor: "black", borderRadius: "50%", margin: "4px"}}></div>
                                <div style={{width: "3px", height: "3px", backgroundColor: "black", borderRadius: "50%", margin: "3px"}}></div>

                            <TimelineConnector />
                        </TimelineSeparator>
                </Timeline>
            </div>

            <div style={window.innerWidth > 1024 ? {position: "fixed", left: 0, right: 0, bottom: 0} : {zIndex:"999"}}>
                <Navigation previousRoute="/skills" previousText="skills" nextRoute="/projects" nextText="projects" />
            </div>

        </div>
    )
}

export default Works